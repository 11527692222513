import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Sett opp varsler som synes" />
    <div className="longtext">
      <h2>Sett opp varsler som synes</h2>
      <p>
        Varslinger i Mente kan være påminnelser du har satt selv eller
        påminnelser Mente mener er viktig for deg.
      </p>

      <p>
        Som standard forsvinner varsler etter noen sekunder på iOS/macOS. Dette
        gjør at du kan misse en påminnelse fordi du hadde fokus et annet sted.
        Heldigvis er dette noe du kan endre i systeminnstillinger, slik at du
        aktivt må klikke på varsler og påminnelser for å få de bort. Se hvordan
        du konfigurerer dette under.
      </p>

      <h3>For iOS</h3>
      <ol>
        <li>Gå til Systeminnstillinger</li>
        <li>Scroll ned til du finner igjen Mente</li>
        <li>Velg Varslinger</li>
        <li>
          Sett "bannerstil" ("banner style") til "vedvarende" ("persistent"),
          som vist i bildet under:
        </li>
      </ol>

      <StaticImage
        src="../../images/help-ios-notifications.png"
        alt="Sett opp varslinger på iOS"
      />

      <h3>For macOS</h3>
      <ol>
        <li>Gå til Systeminnstillinger</li>
        <li>Velg Varslinger / Notifications</li>
        <li>
          Finn Mente i listen og sett "alert style" til "Alert", som vist i
          bildet under:
        </li>
      </ol>

      <StaticImage
        src="../../images/help-macos-notifications.png"
        alt="Sett opp varslinger på macOS"
      />
    </div>
  </Layout>
)

export default Page
